import { render, staticRenderFns } from "./Action_trans_qywxx.vue?vue&type=template&id=0dc7836d&scoped=true"
import script from "./Action_trans_qywxx.vue?vue&type=script&lang=js"
export * from "./Action_trans_qywxx.vue?vue&type=script&lang=js"
import style0 from "./Action_trans_qywxx.vue?vue&type=style&index=0&id=0dc7836d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dc7836d",
  null
  
)

export default component.exports